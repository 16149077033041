import { Box, Button, Link, Modal, SpaceBetween, TextContent, Textarea, Tabs } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import '../styles/feedback-modal.scss';
import { useTranslate } from "src/i18n/useTranslate";
import { useApi } from "src/hooks/useApi";
import { SaveFeedbackApiType } from "src/types/useApi";
import initialMetricsPublisher from "src/metrics";
import * as KatalMetrics from '@amzn/katal-metrics';
import { NOTIFICATIONS, useNotifications } from "./providers/NotificationsProvider";
import { useLocation } from "react-router-dom";
import { ArticleDetailsApiType } from "src/types/useApi";

interface FeedbackModalProps {
    feedbackModalOpen: boolean;
    setFeedbackModalOpen: (open: boolean) => void;
}

export default function FeedbackModal({
    feedbackModalOpen,
    setFeedbackModalOpen,
}: FeedbackModalProps) {

/**
 * Find the full path to a topic by matching its lcmsXmlGUID with the provided topicID
 * @param contentObjects - The object containing the lessons
 * @param topicID - The ID to search for
 * @returns The full path to the topic if found, or null otherwise
 */
const findTopicPath = (contentObjects: any, topicID: string): string | null => {
    let output: string | null = null;
    
    const hasTopicPath = (topics: any[], currentPath: string): boolean => {
      if (!Array.isArray(topics)) {
        return false;
      }
      
      for (const topic of topics) {
        if (!topic) {
          continue;
        }
        
        const topicPath = currentPath ? `${currentPath} : ${topic.name}` : topic.name;
        
        if (topic.lcmsXmlGUID === topicID) {
          output = topicPath;
          return true;
        }
        
        if (topic.topics && Array.isArray(topic.topics) && topic.topics.length > 0) {
          if (hasTopicPath(topic.topics, topicPath)) {
            return true;
          }
        }
      }
      
      return false;
    };
    
    if (!contentObjects?.lessons || !Array.isArray(contentObjects.lessons)) {
      return null;
    }
    
    for (const lesson of contentObjects.lessons) {
      if (!lesson?.topics || !Array.isArray(lesson.topics)) {
        continue;
      }
      
      if (hasTopicPath(lesson.topics, lesson.name)) {
        break;
      }
    }
    
    return output;
  };

    const t = useTranslate();

    type FeedbackType = "GRU Feedback/Bug" | "GRU Feature Request" | "Rate Content";
    const location = useLocation();
    const { pathname, hash } = location;

    let topicId: string | undefined = undefined;
    let artifactId: string | undefined = undefined;
    let topicTitle: string = t('none', "None");
    let artifactTitle: string = t('none', "None");
    let completeTopic: string = t('none', "None");

    const apiResult: ArticleDetailsApiType = useApi({
        url: `learning-artifacts/${pathname.slice(1) || ''}`,
        method: 'GET'
    });

    const getArticleDetails = apiResult?.makeRequest ?? (() => { });
    const articleData = apiResult.data;
    const articleDataLoading = apiResult.loading;
    const articleDataError = apiResult.error;

    if (pathname === "/") {
        topicTitle = t('homepage', "Homepage");
        artifactTitle = t('homepage', "Homepage");
        completeTopic = t('homepage', "Homepage");
    } else if (pathname === "/search") {
        topicTitle = t('search_page', "Search Page");
        artifactTitle = t('search_page', "Search Page");
        completeTopic = t('search_page', "Search Page");
    } else if (pathname === "/favorites") {
        topicTitle = t('favorites_page', "My Favorites Page");
        artifactTitle = t('favorites_page', "My Favorites Page");
        completeTopic = t('favorites_page', "My Favorites Page");
    } else if (pathname === "/recent") {
        topicTitle = t('recent_page', "Recently Viewed Page");
        artifactTitle = t('recent_page', "Recently Viewed Page");
        completeTopic = t('recent_page', "Recently Viewed Page");
    } else {
        artifactId = pathname.slice(1);
        topicId = hash?.split('/')?.[hash?.split('/').length - 2];
        topicTitle = hash?.split('/').pop() || t('none', "None");
        artifactTitle = articleData?.name || t('none', "None");
        completeTopic = findTopicPath(articleData?.contentObjects, topicId) || t('none', "None");
    }

    useEffect(() => {
        if (artifactId) {
            try {
                getArticleDetails({ url: `learning-artifacts/${artifactId}` });
            } catch (err) {
                console.error("Error fetching article details:", err);
            }
        }

        return () => { };
    }, [artifactId]);

    // TODO: Add implementation and remove hardcoded delaration
    // const [selectedType, setSelectedType] = useState<FeedbackType>(
    //     isLearnerSupport ? "Learner Support" : "GRU Feedback/Bug"
    // );
    const [selectedType, setSelectedType] = useState<FeedbackType>("Rate Content");


    const [selectedStarAmount, setSelectedStarAmount] = useState<number>(0);
    const [hoveredStarAmount, setHoveredStarAmount] = useState<number>(0);
    const [feedbackTextContent, setFeedbackTextContent] = useState<string>('');

    const { publishNotification } = useNotifications();

    const saveFeedbackErrorMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('SaveFeedbackError');

    const {
        makeRequest: makeSaveFeedbackRequest,
        data: saveFeedbackResponse,
        error: saveFeedbackRequestError,
        loading: saveFeedbackRequestLoading
    }: SaveFeedbackApiType = useApi({
        method: 'POST',
        url: "/feedback"
    })

    const resetFeedbackInputs = () => {
        setSelectedStarAmount(0);
        setHoveredStarAmount(0);
        setFeedbackTextContent('');
        setFeedbackModalOpen(false);
    }

    const canSave = (): boolean => {
        return selectedStarAmount > 0;
    }

    const submitFeedback = async () => {
        if (canSave()) {
            topicId = topicId || t('none', "None");
            artifactId = artifactId || t('none', "None");
            const feedbackData = {
                rating: selectedStarAmount > 0 ? selectedStarAmount : undefined,
                feedback: feedbackTextContent || undefined,
                pageUrl: window.location.href,
                topicId: topicId,
                artifactId,
            }

            if (selectedStarAmount <= 0) {
                delete feedbackData.rating;
            }

            if (!feedbackTextContent.trim()) {
                delete feedbackData.feedback;
            }

            makeSaveFeedbackRequest({
                data: feedbackData
            })

            resetFeedbackInputs();
        }
    }

    useEffect(() => {
        if (saveFeedbackRequestError) {
            saveFeedbackErrorMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.error.errorType', 'Save Feedback'))
            publishNotification({
                type: "error",
                content: (
                    <>
                        {t('feedback_error', "Your feedback could not be collected at this time. Please try again later")}
                    </>
                ),
                id: NOTIFICATIONS.FEEDBACK_ERROR
            })
        }
    }, [saveFeedbackRequestError]);

    useEffect(() => {
        if (saveFeedbackResponse && saveFeedbackResponse?.status === 201) {
            publishNotification({
                type: "success",
                content: (
                    <>
                        {t('feedback_success', "Thank you for providing feedback!")}
                    </>
                ),
                id: NOTIFICATIONS.FEEDBACK_SUCCESS
            })
        }
    }, [saveFeedbackResponse]);

    useEffect(() => {
        resetFeedbackInputs();
    }, [artifactId, topicId]);

    const starIconButton = (iconNumber: number) => {
        return (
            <div
                onMouseEnter={() => setHoveredStarAmount(iconNumber)}
                onMouseLeave={() => setHoveredStarAmount(0)}
            >
                <Button
                    data-testid={`feedback-${iconNumber}-star-button`}
                    data-feedback-star-button={
                        selectedStarAmount >= iconNumber ? "filled" :
                            hoveredStarAmount >= iconNumber ? "hovered" :
                                "empty"
                    }
                    iconName={selectedStarAmount >= iconNumber || hoveredStarAmount >= iconNumber ? "star-filled" : "star"}
                    variant="icon"
                    iconAlt={t(`feedback_${iconNumber}_star`, `Rate Article ${iconNumber} Stars`)}
                    onClick={() => selectedStarAmount === iconNumber ? setSelectedStarAmount(0) : setSelectedStarAmount(iconNumber)}
                />
            </div>
        )
    }

    return (
        <div data-feedback-modal>
            <Modal
                data-testid="feedback-modal"
                onDismiss={({ detail }) => {
                    detail.reason === "closeButton" ? setFeedbackModalOpen(false) : resetFeedbackInputs();
                }}
                visible={feedbackModalOpen}
                size="large"
                footer={
                    <Box display="block">
                        {/* Left-side button */}
                        {/* <Box float="left">
                            <Button
                                variant="normal"
                                onClick={() => console.log("New left-side button clicked!")}
                            >
                                Capture Screenshot
                            </Button>
                        </Box> */}
                        {/* Right-side buttons (Cancel & Submit) */}
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    variant="link"
                                    onClick={resetFeedbackInputs}
                                    ariaLabel={t('cancel_button', 'Cancel')}
                                >
                                    {t('cancel_button', 'Cancel')}
                                </Button>
                                <Button
                                    variant="primary"
                                    loading={saveFeedbackRequestLoading}
                                    disabled={!canSave()}
                                    data-testid="feedback-save-button"
                                    onClick={submitFeedback}
                                    ariaLabel={t('submit', 'Submit')}
                                >
                                    {t('submit', 'Submit')}
                                </Button>
                            </SpaceBetween>
                        </Box>
                    </Box>
                }
                header={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <h2>{t('feedback_modal_heading', 'Provide Feedback')}</h2>
                        </SpaceBetween>
                    </Box>
                }
            >
                <div data-feedback-modal-content-container style={{ height: "50rem", overflowY: "auto" }}>
                    <div style={{ position: "sticky", top: 0, zIndex: 10, backgroundColor: "white", paddingBottom: "8px" }}>
                        <Tabs
                            activeTabId={selectedType}
                            onChange={({ detail }) => setSelectedType(detail.activeTabId as FeedbackType)}
                            tabs={[
                                // TODO: Add remaning column implementation
                                // {
                                //     id: "GRU Feedback/Bug",
                                //     label: "GRU Feedback/Bug",
                                // },
                                // {
                                //     id: "GRU Feature Request",
                                //     label: "GRU Feature Request",
                                // },
                                {
                                    id: "Rate Content",
                                    label: t('rate_content', 'Rate Content'),
                                },
                            ]}
                        />
                    </div>
                    <SpaceBetween size="xs">
                        {selectedType === "Rate Content" && (
                            <>
                                <div style={{ paddingLeft: ".2rem" }}>
                                    <p style={{ margin: "0rem 0 1rem 0" }}>
                                        <strong>
                                            {t('feedback_header', "If you find a bug or content issue, please submit a ticket")} <a href="https://river.amazon.com/workflow/BOS12/run/wfss-66f22687-59d6-40f4-b33f-828b6239775a/?buildingType=ARLExT&q0=77fcbc1f-2d31-4141-af4f-aa0cc8727df1&q1=&q2=&q3=&q4=">here</a>
                                        </strong>
                                    </p>

                                    <SpaceBetween size="l">
                                        <SpaceBetween size="xs">
                                            <h4 data-feedback-section-title style={{ margin: ".1rem" }}>{t('page_link', 'Page link')}</h4>
                                            <Link onFollow={() => setFeedbackModalOpen(false)}>{window.location.href}</Link>
                                        </SpaceBetween>

                                        <SpaceBetween size="xs">
                                            <h4 data-feedback-section-title style={{ margin: ".1rem" }}>{t('feedback_artifact_title', 'Artifact title')}</h4>
                                            <p data-feedback-section-content style={{ marginTop: "-.2rem" }}>{artifactTitle}</p>
                                        </SpaceBetween>
                                    </SpaceBetween>

                                    <SpaceBetween size="xs">
                                        <h4 data-feedback-section-title style={{ margin: ".1rem", marginTop: "1rem" }}>{t('page_title', 'Topic title')}</h4>
                                        <p data-feedback-section-content style={{ marginTop: "-.2rem", marginBottom: ".1rem" }}>{completeTopic}</p>
                                    </SpaceBetween>
                                </div>

                                <SpaceBetween size="xs">
                                    <h4 data-feedback-section-title style={{ margin: ".4rem" }}>{t('rating', 'Rating')}*</h4>
                                    <div style={{ marginLeft: ".4rem" }}>
                                        <TextContent>
                                            <small>{t('on_a_scale', 'On a scale of 1-5 (1 = Poor, 5 = Excellent), please rate your overall experience with this page. Consider the clarity and structure of the presented information.')}</small>
                                        </TextContent>
                                    </div>
                                    <SpaceBetween size="xs" direction="horizontal">
                                        {starIconButton(1)}
                                        {starIconButton(2)}
                                        {starIconButton(3)}
                                        {starIconButton(4)}
                                        {starIconButton(5)}
                                    </SpaceBetween>
                                </SpaceBetween>

                                <SpaceBetween size="xs">
                                    <h4 data-feedback-section-title style={{ margin: ".4rem" }}>{t('corresponding_feedback', 'Please share what you found most and least helpful about this content.')}</h4>
                                    <Textarea
                                        rows={Math.max(3, (feedbackTextContent.split('\n').length))}
                                        data-testid="feedback-textarea"
                                        onChange={({ detail }) => setFeedbackTextContent(detail.value)}
                                        value={feedbackTextContent}
                                        placeholder={t('enter_feedback_here', "You may enter your feedback here...")}
                                    />
                                </SpaceBetween>
                            </>
                        )}

                        <TextContent>
                            <small>*: {t('feedback_footer', "These questions are required")}</small>
                        </TextContent>
                    </SpaceBetween>
                </div>
            </Modal>
        </div>
    );
}