import { Button, Icon, Link } from "@cloudscape-design/components";

import '../styles/learning-detail-page.scss';
import {useTranslate} from "src/i18n/useTranslate";

const divStyle = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  height: 'calc(100vh - 150px)',
  position: 'relative' as 'relative',
  fontFamily: 'var(--font-family-base-4om3hr, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif)',
  textAlign: 'center' as 'center',
};

const iconStyle = {
  paddingTop: '100px',
  height: '70px',
  lineHeight: '100px',
  textAlign: 'center' as 'center',
};

type NotFoundProps = {
  path: string
}
const CONTACT_LINK = "https://river.amazon.com/BOS12/workflows?buildingType=ARLExT&q0=23682267-25a3-4faa-b4a9-5ceb65d66c60&q1=81cc7c0c-8f26-4679-a67c-680932334103&q2=c6142c77-656b-46b7-b565-08489accf39a&id=c6142c77-656b-46b7-b565-08489accf39a";
const NotFound = ({ path } : NotFoundProps) => {
  const t = useTranslate();
    return (
      <div>
        <div style={divStyle}>
          <div style={iconStyle} className="access-denied-icon-container">
            <Icon name="security" size="inherit" variant="error" />
          </div>
          <h1>{t("not_found_header", "Not Found")}</h1>
            <div style={{ margin: '16px'}}>
              {`${t('not_found_detail','We were not able to find')} ${path}.`}
              <br />
              {t('not_found_contact_us_prefix','Please')} <Link href={CONTACT_LINK} target="_blank">{t('not_found_contact_us','contact us')}</Link>{' '}
              {t('not_found_contact_us_suffix','or try again.')}
            </div>
          <Button iconName="caret-left-filled" href="/">
            {t('not_found_go_home_button','Go to homepage')}
          </Button>
        </div>
      </div>
    );
};

export default NotFound;