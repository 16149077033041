
import React from 'react';
import { useTranslate } from 'src/i18n/useTranslate';
import { useFavorites } from 'src/components/providers/FavoritesProvider';
import { AppLayoutWrapper } from './AppLayoutWrapper';
import { ArtifactsList } from 'src/components/ArtifactsList';

const FavoritesPage = () => {
    const t = useTranslate();
    const { favorites } = useFavorites();

    const content = (
        <ArtifactsList
        numberOfItemsPerPage={12}
        name={t('favorite-breadcrumb', 'My Favorites')}
        emptyText={t("favorites_empty", "You don't have any favorites.")}
        artifacts={favorites.map(fav => {
            return {
                id: fav.id,
                ...fav.cardDetails!
            }
        })}
        />
    );

    return <AppLayoutWrapper content={content} />
};

export default FavoritesPage;