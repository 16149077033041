import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'src/components/providers/AuthProvider';
import initialMetricsPublisher from "src/metrics";
import * as KatalMetrics from '@amzn/katal-metrics';

const UrlTracker: React.FC = () => {
  const location = useLocation();
  const { user } = useAuth();
  const deviceMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('Device');

  useEffect(() => {
    const timer = setTimeout(() => {
      
      if (user && user.hashedUserId) {
        deviceMetricsPublisher.publish(
          new KatalMetrics.Metric.String('gru.device.user', user.hashedUserId)
        );
      } 
      
      // 1500 delay to allow document page to fully render before logging
    }, 1500);

    return () => clearTimeout(timer);
  }, [location.pathname, location.search, location.hash, user]);

  return null;
};

export default UrlTracker;
