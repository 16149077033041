import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import FavoritesPage from 'src/pages/favorites';
import Home from 'src/pages/home-page';
import LearningArtifactDetailPage from 'src/pages/learning-artifact-detail-page';
import NotFoundPage from "src/pages/not-found-page";
import { useNotifications } from './providers/NotificationsProvider';
import { Flashbar } from '@cloudscape-design/components';
import SearchPage from 'src/pages/search-page';
import RecentlyViewed from 'src/pages/recently-viewed';
import { InfoBanner } from './InfoBanner';
import UrlTracker from 'src/components/UrlTracker';
import useHistoryStack from "src/hooks/useHistoryStack";

interface AppRoutesProps{
    hasOpenedInNewTab: boolean;
    setHasOpenedInNewTab: (hasOpenedInNewTab: boolean) => void;
}

export const AppRoutes = ({
    hasOpenedInNewTab,
    setHasOpenedInNewTab,
}: AppRoutesProps) => {

    const { previousPath } = useHistoryStack();
    const { notifications } = useNotifications();
    return (
        <>
            <InfoBanner />
            {notifications.length > 0 && (
                <Flashbar items={notifications} data-notifications-flashbar />
            )}
            <UrlTracker />
            <Routes>
                <Route path="/" element={<Home setHasOpenedInNewTab={setHasOpenedInNewTab} />} />
                <Route path="/not-found" element={<NotFoundPage path={previousPath} />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/favorites" element={<FavoritesPage />} />
                <Route path="/recent" element={<RecentlyViewed />} />
                <Route path="/:artifactId" element={<LearningArtifactDetailPage hasOpenedInNewTab={hasOpenedInNewTab} setHasOpenedInNewTab={setHasOpenedInNewTab} />} />
                <Route path="*" element={<NotFoundPage path={previousPath} />} />
            </Routes>
        </>
    )
}