import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

const useHistoryStack = () => {
    const [historyStack, setHistoryStack] = useState<string[]>([]);
    const location = useLocation();

    useEffect(() => {
        // Add current path to history stack
        setHistoryStack(prev => [...prev, location.pathname]);
    }, [location]);


    return {
        historyStack,
        currentPath: location.pathname,
        previousPath: historyStack[historyStack.length - 2],
    };
};

export default useHistoryStack;